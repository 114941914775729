import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useMemo, useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import { Helmet } from "react-helmet";

function Option({ id, name }) {

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios("https://api.nifs.no/tournaments/"+id +"/");
      console.log(result)
      setData(result.data);
    })();
  }, [id]);

  return (
    <div>
      <div className="w-full flex flex-row">
        {data?.logo ?
          <img className="h-6 w-6 fill-current mr-2" src={data?.logo?.url}></img>

            :
            <div className="h-6 w-6 fill-current mr-2"><Skeleton/></div>
            }
         {name}
       </div>
    </div>
  );
}

export default Option;
