import PropTypes from "prop-types";
import React, { useMemo, useState, useEffect } from "react";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import axios from "axios";
import Header from "./header";
import Menu from "./menu";
import Option from "./option";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable, faTrophy, faBullseye } from '@fortawesome/free-solid-svg-icons'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import { Link, navigate } from "gatsby";
import makeAnimated from 'react-select/animated';
import Select from 'react-select'

function SubMenu({}) {

  const [data, setData] = useState([]);

  const [turneringsId, setTurneringsId] = useState("5");

  const url = typeof window !== 'undefined' ? window.location.pathname : '';

  const turnering = url.split("/")[2];

  const animatedComponents = makeAnimated();

  useEffect(() => {
    (async () => {
      const result = await axios("https://api.nifs.no/tournaments/"+turneringsId +"/");
      setData(result.data);
    })();
  }, [turneringsId]);

  const options = [
    {
      label: "Norge",
      options: [
          { value: "/fotball/eliteserien", label: <Option name="Eliteserien" id="5"/> },
          { value: '/fotball/obosligaen/', label: <Option name="Obosligaen" id="6"/> },
        ]
    },
    {
      label: "England",
      options: [
          { value: '/fotball/premierleague/', label: <Option name="Premier League" id="7"/> }
        ]
    },
  ]

  const selectedValue = () => {
    for(let j in options) {
      for(let i in options[j].options) {
        if(options[j].options[i].value.split("/")[2] === url.split("/")[2]) {
          return options[j].options[i]
        }
      }
    }
  }

  return (
    <div>
      <div className="bg-blue-dark md:block md:bg-white md:border-b">
        <div className="container mx-auto px-4">
          <div className="md:flex">
            <div className="flex -mb-px mr-8 md:w-1/5">
              <Select
                className="w-full py-2"
                value={selectedValue()}
                components={animatedComponents}
                isSearchable={false}
                options={options} onChange={(id) => navigate(id.value)} />
            </div>
            {(turnering !== undefined || turnering === null) && turnering.length !== 0 &&
              <>
                <div class="flex -mb-px mr-8">
                  <Link to={"/fotball/" + turnering + "/terminliste"}
                    className="text-gray-600 py-4 px-1 block hover:text-blue-500 focus:outline-none flex flex-row"
                    activeClassName="text-gray-600 block hover:text-blue-500 focus:outline-none text-blue-500 border-b-2 font-medium border-blue-500">
                    <FontAwesomeIcon className="h-6 w-6 fill-current mr-2" icon={faTable} /> Terminliste
                  </Link>
                </div>
                <div class="flex -mb-px mr-8">
                  <Link to={"/fotball/" + turnering + "/tabell"}
                    className="text-gray-600 py-4 px-1 block hover:text-blue-500 focus:outline-none flex flex-row"
                    activeClassName="sm:w-full text-gray-600 block hover:text-blue-500 focus:outline-none text-blue-500 border-b-2 font-medium border-blue-500">
                    <FontAwesomeIcon className="h-6 w-6 fill-current mr-2" icon={faTrophy} />   Tabell
                  </Link>
                </div>
              </>
            }
            {/*<div class="flex -mb-px mr-8">
              <Link to="/fotball/eliteserien/toppscorere"
                className="text-gray-600 py-4 px-1 block hover:text-blue-500 focus:outline-none flex flex-row"
                activeClassName="text-gray-600 block hover:text-blue-500 focus:outline-none text-blue-500 border-b-2 font-medium border-blue-500">
                 <FontAwesomeIcon className="h-6 w-6 fill-current mr-2" icon={faBullseye} /> Toppscorere
              </Link>
            </div>*/}


          </div>
        </div>
      </div>
    </div>
  );
}

export default SubMenu;
