import PropTypes from "prop-types";
import React, { useMemo, useState, useEffect } from "react";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import axios from "axios";
import Header from "./header";
import Menu from "./menu";
import Option from "./option";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable, faTrophy, faBullseye } from '@fortawesome/free-solid-svg-icons'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import { Link, navigate } from "gatsby";
import makeAnimated from 'react-select/animated';
import Select from 'react-select'



function Layout({ children }) {

  const [modal, setModal] = useState(false);

  const checkModal = e => {
    console.log("test")
    if(modal) {
      setModal(false)
    }
  };

  return (
    <div>
      <div className={"antialiased sans-serif h-full" + (!!modal && "darken")}>
        <div class="bg-blue-800">
          <div class="container mx-auto px-4">
            <div class="flex items-center md:justify-between py-4">
              <div class="w-1/4 md:hidden">
                <svg onClick={() => setModal(!modal)} className="fill-current text-white h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M16.4 9H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1zm0 4H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1zM3.6 7h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1z"/></svg>
              </div>
              <div class="md:w-auto text-center text-white text-2xl font-medium">
                <Link to="/">
                  Sportstabell.com
                </Link>
                <div class="hidden md:block pt-3 text-left text-lg text-white font-small">
                  <Link className="active" activeClassName="active" to="/fotball">
                    Fotball
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
        {modal &&
          (<div className="md:hidden bg-white">
            <Menu/>
          </div>)
        }
        <div className="hidden md:block">
          <Menu/>
        </div>
        <div className="container mx-auto py-6 md:px-6">
          <div className="mb-4 flex justify-between items-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
